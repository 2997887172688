import NoticeIcon from '../assets/images/icons/Notice.svg';
import Roles from './Roles';


// TODO:
/*
* 1. Make title optional
* 2. Make title multi type support ie: (string, node, react element)
* 3. Add child route support
* */


/*
* Route config object supports all react-router's route component props with some additional props ie: (title, permission, children)
* you can add or remove props from config object it's means it is super customizable and support upto N nesting, child routes must follow the same parent shape,
* it means the config object is same for both there is no additional key for child nor for parent.
* you can find route props from here https://reactrouter.com/web/api/Route let's take a look at some additional props
* 1. permission: permission is an optional prop and it's type is an array of roles, permission is used for allowing certain users/roles
*  	to have access of that route and if you skip/omit the permission or it's an empty array that means every authenticated user/role have access to that route.
* 2. title: title is an optional prop and it's type is a string|node it is used for mapping route link into a navigation
* 3. children: children is also an optional prop and it's type is an array of route config objects, children are used for nested routes
* */


export default [
  {
    path: '/main/companies',
    title: 'Company',
    icon: 'icon-company',
    permission: [Roles.ROLE_BO_ADMIN],
  },
  {
    path: '/main/notices',
    title: 'Notice',
    icon: NoticeIcon,
    permission: [Roles.ROLE_BO_NOTICE],
  },
  {
    path: '/main/offices',
    title: 'Office',
    icon: 'icon-home',
    permission: [Roles.ROLE_BO_ADMIN],
    children: [
      {
        path: '/main/offices/all',
        title: 'Office',
        permission: [Roles.ROLE_BO_ADMIN],
      },
      {
        path: '/main/offices/devices',
        title: 'Device',
        permission: [Roles.ROLE_BO_ADMIN],
      },
    ],
  },
  {
    path: '/main/shifts',
    title: 'Shift',
    icon: 'icon-timepicker',
    permission: [Roles.ROLE_BO_ADMIN, Roles.ROLE_BO_EMPLOYEE],
    children: [
      {
        path: '/main/shifts/all',
        title: 'Manage Shift',
        permission: [Roles.ROLE_BO_ADMIN],
      },
      {
        path: '/main/shifts/assigns',
        title: 'Employee Shift',
        permission: [Roles.ROLE_BO_EMPLOYEE],
      },
    ],
  },
  {
    path: '/employees',
    title: 'Employee',
    icon: 'icon-profile',
    permission: [Roles.ROLE_BO_EMPLOYEE],
    children: [
      {
        path: '/employees/all',
        title: 'Employee',
        icon: 'icon-profile',
        permission: [Roles.ROLE_BO_EMPLOYEE],
      },
      {
        path: '/employees/activity',
        title: 'Activity',
        icon: 'icon-graduation',
        permission: [Roles.ROLE_BO_EMPLOYEE],
      },
      {
        path: '/employees/exams',
        title: 'Exam',
        icon: 'icon-graduation',
        permission: [Roles.ROLE_BO_EMPLOYEE],
      },
      {
        path: '/employees/assets',
        title: 'Assets',
        icon: 'icon-product-grid',
        permission: [Roles.ROLE_BO_EMPLOYEE],
      },
      {
        path: '/employees/promotion-transfer',
        title: 'Promotion & Transfer',
        icon: 'icon-product-grid',
        permission: [Roles.ROLE_BO_EMPLOYEE],
      },
    ],
  },
  {
    path: '/employees/attendance',
    title: 'Attendance',
    icon: 'icon-check-square-o',
    permission: [Roles.ROLE_BO_EMPLOYEE],
    children: [
      {
        path: '/employees/attendance/individual',
        title: 'Individual',
        permission: [],
      },
      {
        path: '/employees/attendance/summary',
        title: 'Summary',
        permission: [],
      },
    ],
  },
  {
    path: '/employees/groups',
    title: 'Group',
    icon: 'icon-team',
    permission: [Roles.ROLE_BO_ADMIN],
  },
  {
    path: '/hr',
    title: 'HR Services',
    icon: 'icon-service',
    permission: [Roles.ROLE_BO_ADMIN, Roles.ROLE_BO_EMPLOYEE],
    children: [
      {
        path: '/hr/services',
        title: 'Services',
        permission: [Roles.ROLE_BO_ADMIN],
      },
      {
        path: '/hr/sign-authority',
        title: 'Signature Authority',
        permission: [Roles.ROLE_BO_ADMIN],
      },
    ],
  },
  {
    path: '/employees/application',
    title: 'Application',
    icon: 'icon-calendar',
    permission: [
      Roles.ROLE_BO_LEAVE,
      Roles.ROLE_BO_REMOTE_WORK,
      Roles.ROLE_BO_ADMIN,
    ],
    children: [
      {
        path: '/employees/application/leave',
        title: 'Leave',
        permission: [Roles.ROLE_BO_LEAVE],
      },
      {
        path: '/employees/application/remote-work',
        title: 'Remote Work',
        permission: [Roles.ROLE_BO_REMOTE_WORK],
      },
      {
        path: '/employees/application/holiday',
        title: 'Holiday',
        permission: [Roles.ROLE_BO_ADMIN],
      },
    ],
  },
  {
    path: '/employees/performance',
    title: 'Performance',
    icon: 'icon-stats',
    permission: [Roles.ROLE_BO_KPI, Roles.ROLE_BO_EPR],
    children: [
      {
        path: '/employees/performance/kpi',
        title: 'KPI',
        permission: [Roles.ROLE_BO_KPI],
      },
      {
        path: '/employees/performance/year',
        title: 'Year',
        permission: [Roles.ROLE_BO_KPI],
      },
      {
        path: '/employees/performance/epr',
        title: 'EPR',
        permission: [Roles.ROLE_BO_EPR],
      },
    ],
  },
  {
    path: '/employees/payroll',
    title: 'Payroll',
    icon: 'icon-card',
    permission: [
      Roles.ROLE_BO_PAYROLL,
      Roles.ROLE_BO_LOAN,
      Roles.ROLE_BO_PAY_SCHEDULE,
    ],
    children: [
      {
        path: '/employees/payroll/employee-salary',
        title: 'Basic',
        permission: [Roles.ROLE_BO_PAYROLL],
      },
      {
        path: '/employees/payroll/salary-tax',
        title: 'Tax',
        permission: [Roles.ROLE_BO_PAYROLL],
      },
      {
        path: '/employees/payroll/salary',
        title: 'Salary',
        permission: [Roles.ROLE_BO_SALARY],
      },
      {
        path: '/employees/payroll/bonus',
        title: 'Bonus',
        permission: [Roles.ROLE_BO_SALARY],
      },
      {
        path: '/employees/payroll/pay-schedule',
        title: 'Pay Schedule',
        permission: [Roles.ROLE_BO_PAY_SCHEDULE],
      },
      {
        path: '/employees/payroll/recurring',
        title: 'Recurring',
        permission: [Roles.ROLE_BO_PAYROLL],
      },
      {
        path: '/employees/payroll/pay-schedule-type',
        title: 'Pay Schedule Type',
        permission: [Roles.ROLE_BO_PAY_SCHEDULE],
      },
      {
        path: '/employees/payroll/loan',
        title: 'Loan',
        permission: [Roles.ROLE_BO_LOAN],
      },
    ],
  },
];
