/* eslint-disable indent */
import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
//
import ErrorPage404 from 'routes/errorPages/404';
import asyncComponent from 'util/asyncComponent';

const HRServices = ({ match }) => (
    <Switch>
        <Route
            path={`${match.url}/services`}
            component={asyncComponent(() => import('./list'))}
        />
        <Route
            path={`${match.url}/view/:id`}
            component={asyncComponent(() => import('./view'))}
        />
        <Route
            path={`${match.url}/sign-authority`}
            component={asyncComponent(() => import('./SingAuthority'))}
        />
        <Route path='*' exact component={ErrorPage404} />
    </Switch>
);
HRServices.propTypes = {
    match: PropTypes.object
};
export default HRServices;
