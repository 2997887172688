import { message } from 'antd';
//
import { setLoading } from 'appRedux/reducers/Loading';
import { addPaginationItem, deletePaginationItem, pagePaginationSuccess, updatePaginationItem } from 'appRedux/reducers/Pagination';
import {
  AssetsService,
  CompanyService,
  DevicesService,
  EmployeeService,
  EprsService,
  HolidaysService,
  HRService,
  KpiService,
  LeavesService,
  LoansService,
  OfficesService,
  PayScheduleService,
  RemoteWorkService,
  ReportService,
  SAService,
  ShiftsService,
} from 'services';
import { ALERT_TYPES, PAGINATION_LIMIT, PAGINATION_TYPES } from 'util/constants';
import { showMessage } from 'util/helpers';
import { REST_API } from '../../services/rest.api';
import { selectPaginationItem } from '../reducers/Pagination';

export const fetchEmployees = ({ page = 1, limit, ...searchParams }, body, viewType) => {
  return async (dispatch, getState) => {
    const key = PAGINATION_TYPES.employees;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const { items, pagination: { self, last } } = getState().pagination[key];

      const res = (!(parseInt(self) === last) || Object.values(searchParams).length > 0) && await EmployeeService.search(
        {
          limit: limit || PAGINATION_LIMIT,
          page,
          sort: 'id,desc',
          ...searchParams
        }, body
      );
      const allItems = (viewType !== 'table' && page > 1) ? [...items, ...res.items] : res.items;
      res.items = allItems;
      dispatch(pagePaginationSuccess({
        data: res,
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const addOrUpdateEmployee = payload => {
  return async dispatch => {
    const key = PAGINATION_TYPES.employees;
    const { id, ...rest } = payload;
    let res;
    rest.photo = payload.photo ? payload.photo.split(',')[1] : null;
    if (!id) {
      res = await EmployeeService.create(rest);
      if (res) {
        dispatch(addPaginationItem({
          value: res,
          key
        }));
        message.success('employee created successfully');
      }
    } else {
      res = await EmployeeService.patch(id, rest);
      if (res) {
        dispatch(updatePaginationItem({
          key,
          id,
          value: res
        }));
        message.success('employee updated successfully');
      }
    }
    return res;
  };
};

export const deleteEmployee = id => {
  return async dispatch => {
    const key = PAGINATION_TYPES.employees;
    const loadingObj = {
      key,
      value: true
    };
    dispatch(setLoading(loadingObj));
    let res;
    if (id) {
      try {
        res = await EmployeeService.remove({ employee_id: id });
        if (res?.success) {
          dispatch(deletePaginationItem({
            key,
            value: id,
          }));
          showMessage(ALERT_TYPES.SUCCESS, 'Employee has been successfully deleted!');
        }
      } catch (error) {
        // showMessage(ALERT_TYPES.ERROR, 'Unexpected error when Employee deleted!');
      }
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
    return res;
  };
};

export const fetchAssets = id => {
  return async dispatch => {
    const key = PAGINATION_TYPES.assets;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await AssetsService.find(id);
      dispatch(pagePaginationSuccess({
        data: { count: res.length, total: res.length, items: res.reverse() },
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const addOrUpdateAsset = payload => {
  return async dispatch => {
    const key = PAGINATION_TYPES.assets;
    const { id, ...rest } = payload;
    let res;
    if (!id) {
      res = await AssetsService.create(rest);
      res && dispatch(addPaginationItem({
        value: res,
        key
      }));
    } else {
      res = await AssetsService.patch(id, rest);
      res && dispatch(updatePaginationItem({
        key,
        id,
        value: res
      }));
    }
    return res;
  };
};

export const deleteAsset = id => {
  return async dispatch => {
    const key = PAGINATION_TYPES.assets;
    const loadingObj = {
      key,
      value: true
    };
    dispatch(setLoading(loadingObj));
    let res;
    if (id) {
      res = await AssetsService.remove({ asset_id: id });
      if (res?.success)
        dispatch(deletePaginationItem({
          key,
          value: id,
        }));
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
    return res;
  };
};

export const fetchRemoteWorks = (query) => {
  return async dispatch => {
    const key = PAGINATION_TYPES.remote_work;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await RemoteWorkService.find(query);
      res && dispatch(pagePaginationSuccess({
        data: res,
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const fetchLeaves = (query) => {
  return async dispatch => {
    const key = PAGINATION_TYPES.leave;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await LeavesService.find(query);
      res && dispatch(pagePaginationSuccess({
        data: res,
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const fetchLeaveTypes = () => {
  return async dispatch => {
    const key = PAGINATION_TYPES.leave_types;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await LeavesService.getItem(REST_API.LEAVE.TYPES);
      dispatch(pagePaginationSuccess({
        data: { count: res.length, total: res.length, items: res.reverse() },
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const addOrUpdateLeaveType = payload => {
  return async dispatch => {
    const key = PAGINATION_TYPES.leave_types;
    const route = REST_API.LEAVE.TYPES;
    const { id, ...rest } = payload;
    let res;
    if (!id) {
      res = await LeavesService.createItem(route, rest);
      res && dispatch(addPaginationItem({
        value: res,
        key
      }));
    } else {
      res = await LeavesService.patchItem(id, route, rest);
      res && dispatch(updatePaginationItem({
        key,
        id,
        value: res
      }));
    }
    return res;
  };
};

export const deleteLeaveType = id => {
  return async dispatch => {
    const key = PAGINATION_TYPES.leave_types;
    const route = REST_API.LEAVE.TYPES;
    const loadingObj = {
      key,
      value: true
    };
    dispatch(setLoading(loadingObj));
    let res;
    if (id) {
      res = await LeavesService.removeItem(route, { type_id: id });
      if (res?.success)
        dispatch(deletePaginationItem({
          key,
          value: id,
        }));
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
    return res;
  };
};

export const fetchDevelopmentArea = () => {
  return async dispatch => {
    const key = PAGINATION_TYPES.development_areas;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await EprsService.getItem(REST_API.EPRS.DEVELOPMENT_AREA);
      dispatch(pagePaginationSuccess({
        data: { count: res.length, total: res.length, items: res.reverse() },
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};
export const addOrUpdateDevelopmentArea = payload => {
  return async dispatch => {
    const key = PAGINATION_TYPES.development_areas;
    const route = REST_API.EPRS.DEVELOPMENT_AREA.split('_')[1];
    const { id, ...rest } = payload;
    let res;
    if (!id) {
      res = await EprsService.createItem(route, rest);
      dispatch(addPaginationItem({
        value: res,
        key
      }));
    } else {
      res = await EprsService.patchItem(id, route, rest);
      res && dispatch(updatePaginationItem({
        key,
        id,
        value: res
      }));
    }
    return res;
  };
};
export const deleteDevelopmentArea = id => {
  return async dispatch => {
    const key = PAGINATION_TYPES.development_areas;
    const route = REST_API.EPRS.DEVELOPMENT_AREA.split('_')[1];
    const loadingObj = {
      key,
      value: true
    };
    dispatch(setLoading(loadingObj));
    let res;
    if (id) {
      res = await EprsService.removeItem(route, { development_area_id: id });
      if (res?.success)
        dispatch(deletePaginationItem({
          key,
          value: id
        }));
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
    return res;
  };
};

export const fetchDurations = (id) => {
  return async dispatch => {
    const key = PAGINATION_TYPES.leave_durations;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await LeavesService.getItem(REST_API.LEAVE.DURATIONS);
      res && dispatch(pagePaginationSuccess({
        data: { count: res.length, total: res.length, items: res.reverse() },
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};
export const addOrUpdateDuration = payload => {
  return async dispatch => {
    const key = PAGINATION_TYPES.leave_durations;
    const route = REST_API.LEAVE.DURATIONS;
    const { id, ...rest } = payload;
    let res;
    if (!id) {
      res = await LeavesService.createItem(route, rest);
      res && dispatch(addPaginationItem({
        value: res,
        key
      }));
    } else {
      res = await LeavesService.patchItem(id, route, rest);
      res && dispatch(updatePaginationItem({
        key,
        id,
        value: res
      }));
    }
    return res;
  };
};

export const deleteDuration = id => {
  return async dispatch => {
    const key = PAGINATION_TYPES.leave_durations;
    const route = REST_API.LEAVE.DURATIONS;
    const loadingObj = {
      key,
      value: true
    };
    dispatch(setLoading(loadingObj));
    let res;
    if (id) {
      res = await LeavesService.removeItem(route, { duration_id: id });
      if (res?.success)
        dispatch(deletePaginationItem({
          key,
          value: id,
        }));
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
    return res;
  };
};

export const fetchCompanies = (id) => {
  return async dispatch => {
    const key = PAGINATION_TYPES.companies;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await CompanyService.find(id);
      res && dispatch(pagePaginationSuccess({
        data: { count: res.length, total: res.length, items: res.reverse() },
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const addOrUpdateCompany = payload => {
  return async dispatch => {
    const key = PAGINATION_TYPES.companies;
    const { id, ...rest } = payload;
    let res;
    if (!id) {
      res = await CompanyService.create(rest);
      res && dispatch(addPaginationItem({
        value: res,
        key
      }));
    } else {
      res = await CompanyService.patch(id, rest);
      res && dispatch(updatePaginationItem({
        key,
        id,
        value: res
      }));
    }
    return res;
  };
};
export const deleteCompany = id => {
  return async dispatch => {
    const key = PAGINATION_TYPES.companies;
    const loadingObj = {
      key,
      value: true
    };
    dispatch(setLoading(loadingObj));
    let res;
    if (id) {
      res = await CompanyService.remove({ company_id: id });
      if (res?.success)
        dispatch(deletePaginationItem({
          key,
          value: id,
        }));
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
    return res;
  };
};

export const fetchHolidays = (query) => {
  return async dispatch => {
    const key = PAGINATION_TYPES.holidays;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await HolidaysService.find(query);
      dispatch(pagePaginationSuccess({
        data: { count: res.length, total: res.length, items: res.reverse() },
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const addOrUpdateHoliday = payload => {
  return async dispatch => {
    const key = PAGINATION_TYPES.holidays;
    const { id, ...rest } = payload;
    let res;
    if (!id) {
      res = await HolidaysService.create(rest);
      res && dispatch(addPaginationItem({
        value: res,
        key
      }));
    } else {
      res = await HolidaysService.patch(id, rest);
      res && dispatch(updatePaginationItem({
        key,
        id,
        value: res
      }));
    }
    return res;
  };
};

export const deleteHoliday = id => {
  return async dispatch => {
    const key = PAGINATION_TYPES.holidays;
    const loadingObj = {
      key,
      value: true
    };
    dispatch(setLoading(loadingObj));
    let res;
    if (id) {
      res = await HolidaysService.remove({ holiday_id: id });
      if (res?.success)
        dispatch(deletePaginationItem({
          key,
          value: id,
        }));
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
    return res;
  };
};


export const fetchAssignShifts = (query) => {
  return async dispatch => {
    const key = PAGINATION_TYPES.assign_shifts;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await EmployeeService.getItem(REST_API.EMPLOYEES_ROUTE.SHIFTS, query);
      res && dispatch(pagePaginationSuccess({
        data: res,
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const fetchShifts = (query) => {
  return async dispatch => {
    const key = PAGINATION_TYPES.shifts;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await ShiftsService.find(query);
      res && dispatch(pagePaginationSuccess({
        data: { count: res.length, total: res.length, items: res.reverse() },
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const addOrUpdateShift = payload => {
  return async dispatch => {
    const key = PAGINATION_TYPES.shifts;
    const { id, ...rest } = payload;
    let res;
    if (!id) {
      res = await ShiftsService.create(rest);
      if (res) {
        message.success('Manage shift Created successfully');
      }
      res && dispatch(addPaginationItem({
        value: res,
        key
      }));
    } else {
      res = await ShiftsService.patch(id, rest);
      if (res) {
        message.success('Manage shift Update successfully');
      }
      res && dispatch(updatePaginationItem({
        key,
        id,
        value: res
      }));
    }
    return res;
  };
};

export const deleteShift = id => {
  return async dispatch => {
    const key = PAGINATION_TYPES.shifts;
    const loadingObj = {
      key,
      value: true
    };
    dispatch(setLoading(loadingObj));
    let res;
    if (id) {
      res = await ShiftsService.remove({ shift_id: id });
      if (res?.success)
        dispatch(deletePaginationItem({
          key,
          value: id,
        }));
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
    return res;
  };
};

export const fetchOffices = (query) => {
  return async dispatch => {
    const key = PAGINATION_TYPES.offices;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await OfficesService.find(query);
      res && dispatch(pagePaginationSuccess({
        data: { count: res.length, total: res.length, items: res.reverse() },
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const addOrUpdateOffices = payload => {
  return async dispatch => {
    const key = PAGINATION_TYPES.offices;
    const { id, ...rest } = payload;
    let res;
    if (!id) {
      res = await OfficesService.create(rest);
      res && dispatch(addPaginationItem({
        value: res,
        key
      }));
    } else {
      res = await OfficesService.patch(id, rest);
      res && dispatch(updatePaginationItem({
        key,
        id,
        value: res
      }));
    }
    return res;
  };
};

export const fetchDevices = (query) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.devices;
    const loadingObj = {
      key,
      value: true,
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await DevicesService.find(query);
      res &&
        dispatch(
          pagePaginationSuccess({
            data: { ...res },
            key,
          })
        );
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const deleteDevices = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.devices;
    const loadingObj = {
      key,
      value: true,
    };
    dispatch(setLoading(loadingObj));
    let res;
    if (id) {
      res = await DevicesService.remove({ device_id: id });
      if (res?.success)
        dispatch(
          deletePaginationItem({
            key,
            value: id,
          })
        );
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
    return res;
  };
};


export const addOrUpdateDevices = (payload) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.devices;
    const { id, ...rest } = payload;
    let res;
    if (!id) {
      res = await DevicesService.create(rest);
      res &&
        dispatch(
          addPaginationItem({
            value: res,
            key,
          })
        );
    } else {
      res = await DevicesService.patch(id, rest);
      res &&
        dispatch(
          updatePaginationItem({
            key,
            id,
            value: res,
          })
        );
    }
    return res;
  };
};

export const deleteOffices = id => {
  return async dispatch => {
    const key = PAGINATION_TYPES.offices;
    const loadingObj = {
      key,
      value: true
    };
    dispatch(setLoading(loadingObj));
    let res;
    if (id) {
      res = await OfficesService.remove({ office_id: id });
      if (res?.success)
        dispatch(deletePaginationItem({
          key,
          value: id,
        }));
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
    return res;
  };
};

export const fetchKPI = (query) => {
  return async dispatch => {
    const key = PAGINATION_TYPES.kpi;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await KpiService.find(query);
      res && dispatch(pagePaginationSuccess({
        data: { ...res },
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const getKPIById = (id) => {
  return async (dispatch, getState) => {
    const key = PAGINATION_TYPES.kpi;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await KpiService.findById(id);
      const epr = selectPaginationItem({ key, id })(getState());
      if (!epr)
        res && dispatch(addPaginationItem({
          value: res,
          key
        }));
      else
        res && dispatch(updatePaginationItem({
          key,
          id,
          value: res
        }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};
export const fetchPaySchedules = (query) => {
  return async dispatch => {
    const key = PAGINATION_TYPES.pay_schedules;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await PayScheduleService.find(query);
      res && dispatch(pagePaginationSuccess({
        data: res,
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};
export const addOrUpdatePaySchedules = payload => {
  return async dispatch => {
    const key = PAGINATION_TYPES.pay_schedules;
    const { id, ...rest } = payload;
    let res;
    if (!id) {
      res = await PayScheduleService.create(rest);
      res && dispatch(addPaginationItem({
        value: res,
        key
      }));
    } else {
      res = await PayScheduleService.patch(id, rest);
      res && dispatch(updatePaginationItem({
        key,
        id,
        value: res
      }));
    }
    return res;
  };
};

export const deletePaySchedules = id => {
  return async dispatch => {
    const key = PAGINATION_TYPES.pay_schedules;
    const loadingObj = {
      key,
      value: true
    };
    // dispatch(setLoading(loadingObj));
    let res;
    if (id) {
      res = await PayScheduleService.remove({ pay_schedule_id: id });
      if (res.success)
        dispatch(deletePaginationItem({
          key,
          value: id,
        }));
    }
    loadingObj.value = false;
    // dispatch(setLoading(loadingObj));
    return res;
  };
};
export const fetchLoans = (query) => {
  return async dispatch => {
    const key = PAGINATION_TYPES.loans;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await LoansService.find(query);
      res && dispatch(pagePaginationSuccess({
        data: res,
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const fetchEPR = (query) => {
  return async dispatch => {
    const key = PAGINATION_TYPES.epr;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await EprsService.find(query);
      res && dispatch(pagePaginationSuccess({
        data: { ...res },
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};
export const addOrUpdateLoans = payload => {
  return async dispatch => {
    const key = PAGINATION_TYPES.loans;
    const { id, ...rest } = payload;
    let res;
    if (!id) {
      res = await LoansService.create(rest);
      dispatch(addPaginationItem({
        value: res,
        key
      }));
    } else {
      res = await LoansService.patch(id, rest);
      dispatch(updatePaginationItem({
        key,
        id,
        value: res
      }));
    }
    return res;
  };
};

export const deleteLoans = id => {
  return async dispatch => {
    const key = PAGINATION_TYPES.loans;
    const loadingObj = {
      key,
      value: true
    };
    // dispatch(setLoading(loadingObj));
    let res;
    if (id) {
      res = await LoansService.remove({ loan_id: id });
      if (res.success)
        dispatch(deletePaginationItem({
          key,
          value: id,
        }));
    }
    loadingObj.value = false;
    // dispatch(setLoading(loadingObj));
    return res;
  };
};

export const getEPRById = (id) => {
  return async (dispatch, getState) => {
    const key = PAGINATION_TYPES.epr;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await EprsService.findById(id);
      const section_a = res?.section_a;
      res.section_a = updateEPRSectionA(section_a);

      const epr = selectPaginationItem({ key, id })(getState());
      if (!epr)
        res && dispatch(addPaginationItem({
          value: res,
          key
        }));
      else
        res && dispatch(updatePaginationItem({
          key,
          id,
          value: res
        }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const fetchSAS = (query) => {
  return async dispatch => {
    const key = PAGINATION_TYPES.signature_authority;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await SAService.find(query);
      res && dispatch(pagePaginationSuccess({
        data: { ...res },
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const addOrUpdateSAS = payload => {
  return async dispatch => {
    const key = PAGINATION_TYPES.hr_services;
    const { id, ...rest } = payload;
    let res;
    if (!id) {
      res = await SAService.create(rest);
      res && dispatch(addPaginationItem({
        value: res,
        key
      }));
    } else {
      res = await SAService.patch(id, payload);
      res && dispatch(updatePaginationItem({
        key,
        id,
        value: res
      }));
    }
    return res;
  };
};

export const deleteSAService = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.signature_authority;
    const loadingObj = {
      key,
      value: true,
    };
    let res;
    if (id) {
      res = await SAService.remove({ sign_authority_id: id });
      if (res.success)
        dispatch(
          deletePaginationItem({
            key,
            value: id,
          })
        );
    }
    loadingObj.value = false;
    return res;
  };
};

export const fetchHRS = (query) => {
  return async dispatch => {
    const key = PAGINATION_TYPES.hr_services;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await HRService.find(query);
      res && dispatch(pagePaginationSuccess({
        data: { ...res },
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const addOrUpdateHRS = payload => {
  return async dispatch => {
    const key = PAGINATION_TYPES.hr_services;
    const { id, ...rest } = payload;
    let res;
    if (!id) {
      res = await HRService.create(rest);
      res && dispatch(addPaginationItem({
        value: res,
        key
      }));
    } else {
      res = await HRService.patch(id, payload);
      res && dispatch(updatePaginationItem({
        key,
        id,
        value: res
      }));
    }
    return res;
  };
};

export const getHRSById = (id) => {
  return async (dispatch, getState) => {
    const key = PAGINATION_TYPES.hr_services;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await HRService.findById(id);
      const epr = selectPaginationItem({ key, id })(getState());
      if (!epr)
        res && dispatch(addPaginationItem({
          value: res,
          key
        }));
      else
        res && dispatch(updatePaginationItem({
          key,
          id,
          value: res
        }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const handleHRServiceActions = (payload) => {
  return async (dispatch) => {
    const { id, key, route } = payload;
    const loadingObj = {
      key,
      value: true,
    };
    if (id) {
      dispatch(setLoading(loadingObj));
      const res = await HRService.patchAction(route, { service_request_id: id });
      res &&
        dispatch(
          updatePaginationItem({
            key,
            id,
            value: res,
          })
        );
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const fetchIndividualAttendance = (query) => {
  return async dispatch => {
    const key = PAGINATION_TYPES.attendance_individual;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await ReportService.getItem(REST_API.REPORT.ATTENDANCE_INDIVIDUAL, query);
      res && dispatch(pagePaginationSuccess({
        data: { count: res.length, total: res.length, items: res },
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));

  };
};

export const addAttendance = value => {
  return async dispatch => {
    const res = await ReportService.createAttendance(value);
    if (res?.success) {
      showMessage(ALERT_TYPES.SUCCESS, 'Attendance has been successfully added!');
    }
    return res;
  };
};

export const fetchSummaryAttendance = (query) => {
  return async dispatch => {
    const key = PAGINATION_TYPES.attendance_summary;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await ReportService.getItem(REST_API.REPORT.ATTENDANCE_SUMMARY, query);
      res && dispatch(pagePaginationSuccess({
        data: { count: res.length, total: res.length, items: res },
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));

  };
};
const updateEPRSectionA = section_a => {
  const { objectives } = section_a || {};
  objectives.map(element => {
    const { objective_kpi } = element;
    element.objective_id = objective_kpi.objective.id;
    element.objective_name = objective_kpi.objective.name;
    return element;
  });
  let newObjects = objectives.reduce((r, a) => {
    r[a.objective_id] = r[a.objective_id] || [];
    r[a.objective_id].push(a);
    return r;
  }, Object.create(null));

  const result = Object.keys(newObjects).map(v => {
    const item = newObjects[v];
    const { objective_id, objective_name } = item[0];
    let total_weight = 0;
    item.forEach(element => {
      total_weight = total_weight + element.objective_kpi.weight;
    });
    return {
      objective_id,
      objective_name,
      objective_kpis: item,
      total_weight,
    };
  });
  section_a.objectives = result;
  return section_a;
};


export const fetchYear = () => {
  return async dispatch => {
    const key = PAGINATION_TYPES.year;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await KpiService.getItem(REST_API.KPI.YEARS);
      dispatch(pagePaginationSuccess({
        data: { count: res.length, total: res.length, items: res.reverse() },
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};
export const addOrUpdateYear = payload => {
  return async dispatch => {
    const key = PAGINATION_TYPES.year;
    const route = REST_API.KPI.YEARS.split('_')[1];
    const { id, ...rest } = payload;
    let res;
    if (!id) {
      res = await KpiService.createItem(route, rest);
      dispatch(addPaginationItem({
        value: res,
        key
      }));
    } else {
      res = await KpiService.patchItem(id, route, rest);
      res && dispatch(updatePaginationItem({
        key,
        id,
        value: res
      }));
    }
    return res;
  };
};
export const deleteYear = id => {
  return async dispatch => {
    const key = PAGINATION_TYPES.year;
    const route = REST_API.KPI.YEARS.split('_')[1];
    const loadingObj = {
      key,
      value: true
    };
    dispatch(setLoading(loadingObj));
    let res;
    if (id) {
      res = await KpiService.removeItem(route, { year_id: id });
      if (res?.success)
        dispatch(deletePaginationItem({
          key,
          value: id
        }));
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
    return res;
  };
};

/* Common pagination actions starts */

export const fetchItems = (payload) => {
  const { key, ServiceName, ...body } = payload || {};
  return async (dispatch) => {
    let res;
    const loadingObj = {
      key,
      value: true,
    };
    try {
      dispatch(setLoading(loadingObj));
      res = await ServiceName.find(body);
      if (res) {
        dispatch(
          pagePaginationSuccess({
            data: { ...res },
            key,
          }),
        );
      }
      return res;
    } catch (e) {
      console.error('FETCH PAGINATION ITEMS', e);
    } finally {
      loadingObj.value = false;
      dispatch(setLoading(loadingObj));
    }
  };
};

export const deleteItem = (payload) => {
  const { key, ServiceName, ...body } = payload || {};
  const id = Object.values(body)[0];
  return async (dispatch) => {
    let res;
    const loadingObj = {
      key,
      value: true,
    };
    try {
      dispatch(setLoading(loadingObj));
      if (id) {
        res = await ServiceName.remove(body);
        if (res?.success) {
          dispatch(
            deletePaginationItem({
              key,
              value: id,
            }),
          );
        }
      }
      return res;
    } catch (e) {
      console.error('DELETE PAGINATION ITEM', e);
    } finally {
      loadingObj.value = false;
      dispatch(setLoading(loadingObj));
    }
  };
};

export const addOrUpdateItems = (payload) => {
  const { id, key, ServiceName, ...body } = payload || {};
  return async (dispatch) => {
    let res;
    const loadingObj = {
      key,
      value: true,
    };
    try {
      dispatch(setLoading(loadingObj));
      if (!id) {
        res = await ServiceName.create(body);
        if (res) {
          dispatch(
            addPaginationItem({
              key,
              value: res,
            }),
          );
        }
      } else {
        res = await ServiceName.patch(id, body);
        if (res) {
          dispatch(
            updatePaginationItem({
              id,
              key,
              value: res,
            }),
          );
        }
      }
      return res;
    } catch (e) {
      console.error('ADD OR UPDATE PAGINATION ITEMS', e);
    } finally {
      loadingObj.value = false;
      dispatch(setLoading(loadingObj));
    }
  };
};

/* Common pagination actions ends */
