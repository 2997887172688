import { message } from 'antd';
//
import { setLoading } from 'appRedux/reducers/Loading';
import { addPaginationItem, pagePaginationSuccess, selectPaginationItem, updatePaginationItem } from 'appRedux/reducers/Pagination';
import { EmployeeService } from 'services';
import { REST_API } from 'services/rest.api';
import { PAGINATION_TYPES } from 'util/constants';
import { paginateObj } from '../reducers/Pagination';

export const fetchAllEmployees = query => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.employees;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await EmployeeService.search(query);
      dispatch(pagePaginationSuccess({
        data: { ...paginateObj, ...{ count: res.length, total: res.length, items: res.reverse() } },
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Employee', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const clearEmployees = () => {
  return async dispatch => {
    const key = PAGINATION_TYPES.employees;
    const loadingObj = {
      key,
      value: true
    };
    dispatch(setLoading(loadingObj));
    try {
      dispatch(pagePaginationSuccess({
        data: { ...paginateObj },
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Employee', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};
export const getEmployeeById = id => {
  return async (dispatch, getState) => {
    const key = PAGINATION_TYPES.employees;
    const loadingObj = {
      key,
      value: true
    };
    dispatch(setLoading(loadingObj));
    let res;
    if (id) {
      res = await EmployeeService.findById(id);
      const employee = selectPaginationItem({ key, id })(getState());
      if (!employee)
        dispatch(addPaginationItem({
          value: res,
          key
        }));
      else
        dispatch(updatePaginationItem({
          key,
          id,
          value: res
        }));
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const deleteFamilyMember = (id, employeeId) => {
  return async (dispatch, getState) => {
    const key = PAGINATION_TYPES.employees;
    let res;
    if (id) {
      res = await EmployeeService.removeItem(REST_API.EMPLOYEES_ROUTE.FAMILY_MEMBER, { member_id: id });
      if (res?.success) {
        const employee = selectPaginationItem({ key, id: employeeId })(getState());
        const { data } = employee;
        res = {
          ...employee,
          data: {
            ...data,
            family: { ...data.family, members: data.family.members.filter(item => item.id !== parseInt(id)) }
          }
        };
        dispatch(updatePaginationItem({
          key,
          id: employeeId,
          value: res
        }));
      }
    }
    return res;
  };
};

export const addOrUpdateEmployeeItem = payload => {
  return async dispatch => {
    const key = PAGINATION_TYPES.employees;
    const { id, item_id, route, ...rest } = payload;
    let res;
    if (id) {
      if (item_id) {
        res = await EmployeeService.patchItem(id, route, rest);
      } else {
        /**
         * ADD USER RESPONSE
         * {
            firebase_uuid: null
            id: 2
            original: {id: 2146, eid: "ee2146"}
            user: {id: 6, email: "dev11@com.com", roles: ["ROLE_EMPLOYEE", "ROLE_USER"], firstname: null, …}
         * }
         */
        res = await EmployeeService.createItem(id, route, rest);
        if (route === REST_API.ADD_USER) {
          let { original, user } = res || {};
          original.employee_user = user;
          res = original;
        }
      }
    }
    if (route === REST_API.EMPLOYEES_ROUTE.DELETE_SHIFT) {
      const body = { employee_ids: payload.employee_ids, shift_id: payload.shift_id, date: payload.date };
      res = await EmployeeService.removeItem(route, body);
      if (res) {
        message.success(`${res.message}`);
      }
    }
    res && dispatch(updatePaginationItem({
      key,
      id,
      value: res
    }));
    return res;
  };
};

export const deleteEmployeeGroup = payload => {
  return async (dispatch, getState) => {
    const key = PAGINATION_TYPES.employees;
    const { id, employeeId, route } = payload;
    let res;
    if (id) {
      res = await EmployeeService.removeItem(route, { employee_id: employeeId, tree_id: payload.id });
      if (res?.success) {
        const employee = selectPaginationItem({ key, id: employeeId })(getState());
        const { groups } = employee;
        res = {
          ...employee,
          groups: groups.filter(item => item.id !== parseInt(id))
        };
        dispatch(updatePaginationItem({
          key,
          id: employeeId,
          value: res
        }));
      }
    }
    return res;
  };
};

export const deleteEmployeeItem = payload => {
  return async (dispatch, getState) => {
    const key = PAGINATION_TYPES.employees;
    const { id, employeeId, route, body } = payload;
    let res;
    if (id) {
      res = await EmployeeService.removeItem(route, body);
      if (res?.success) {
        const employee = selectPaginationItem({ key, id: employeeId })(getState());
        const { data } = employee;
        let items;
        switch (route) {
        case REST_API.EMPLOYEES_ROUTE.EDUCATIONS:
          items = 'educations';
          break;
        case REST_API.EMPLOYEES_ROUTE.ASSETS_REMOVE:
          items = 'assets';
          break;
        case REST_API.EMPLOYEES_ROUTE.REMOVE_DEVICE:
          items = 'devices';
          break;
        case REST_API.EMPLOYEES_ROUTE.FILES:
          items = 'files';
          break;
        case REST_API.EMPLOYEES_ROUTE.CONTACTS:
          items = 'contacts';
          break;
        case REST_API.EMPLOYEES_ROUTE.REFERENCES:
          items = 'references';
          break;
        case REST_API.EMPLOYEES_ROUTE.SKILLS:
          items = 'skills';
          break;
        case REST_API.EMPLOYEES_ROUTE.ACHIEVEMENTS:
          items = 'achievements';
          break;
        case REST_API.EMPLOYEES_ROUTE.TRAININGS:
          items = 'trainings';
          break;
        case REST_API.EMPLOYEES_ROUTE.HISTORIES:
          items = 'history';
          break;
        case REST_API.EMPLOYEES_ROUTE.LANGUAGES:
          items = 'languages';
          break;
        default:
          break;
        }
        res = {
          ...employee,
          data: {
            ...data,
            [items]: data[items].filter(item => item.id !== parseInt(id))
          }
        };
        dispatch(updatePaginationItem({
          key,
          id: employeeId,
          value: res
        }));
      }
    }
    return res;
  };
};


