export const REST_API = {
  loginUri: 'login_check',
  impersonate: 'impersonate',
  emailRequest: 'reset/password/email/request',
  verifyToken: 'reset/password/email/validate/',
  passwordReset: 'reset/password/email',
  ADD_USER: 'users/employees',
  assets: 'employees/assets',
  designations: 'employees/designations',
  pay_schedules: 'employees/pay-schedules',
  pay_recurrings: 'employees/pay-schedules/recurrings',
  pay_recurrings_import_csv: 'employees/pay-schedules/recurrings/import/csv',
  attendances_import_csv: 'attendances/import/csv',
  attendances_check_csv: 'attendances/import/csv/check',
  pay_schedule_type: 'employees/pay-schedules/types',
  pay_schedule_import: 'employees/pay-schedules/import/csv',
  pay_schedule_status: 'PAY-SCHEDULES_/statuses',
  PAY_SCHEDULES_TYPES_NATURES: 'PAY-SCHEDULES_/natures',
  employee_transfer_import_csv: 'employees/transfers/import/csv',
  employee_promotion_import_csv: 'employees/promotions/import/csv',
  loans: 'employees/loans',
  loans_types: 'LOAN_/types',
  loan_status: 'LOAN_/statuses',
  LOANS_ROUTE: {
    LOANS_SUBMIT: '/submit',
    LOANS_APPROVE: '/approve',
  },
  salaries: 'salaries',
  salaries_status: 'LOAN_/statuses',
  SALARY_ROUTE: {
    SALARY_HOLD: '/employees/hold',
    SALARY_HOLD_REMOVE: '/employees/remove-hold',
    SALARY_APPROVE: '/approve',
    SALARY_PAYSLIP: '',
    SALARY_STATUS: 'SALARY_/statuses',
    SALARY_PROCESS_STATUS: 'SALARY-PROCESS_/process-statuses',
  },
  bonuses: 'salaries/bonuses',
  BONUS_ROUTE: {
    BONUS_HOLD: '/employees/hold',
    BONUS_HOLD_REMOVE: '/employees/remove-hold',
    BONUS_APPROVE: '/approve',
    BONUS_STATUS: 'BONUS_/statuses',
    BONUS_FESTIVALS: 'BONUS_/festivals',
  },
  employees: 'employees',
  EMPLOYEES_ROUTE: {
    SHIFTS: 'EMPLOYEE_/shifts',
    LEVELS: 'EMPLOYEE_/levels',
    GRADES: 'EMPLOYEE_/grades',
    SALARIES: '/salaries',
    SETTING: '/setting',
    ENABLE: '/enable',
    DISABLE: '/disable',
    ACHIEVEMENTS: '/achievements',
    REFERENCES: '/references',
    CONTACTS: '/contacts',
    FILES: '/files',
    ADDRESS: '/address',
    EDUCATIONS: '/educations',
    FAMILY: '/family',
    FAMILY_MEMBER: '/family/members',
    GENERAL: '/general',
    SEPARATION: '/separation',
    HISTORIES: '/histories',
    LANGUAGES: '/languages',
    SKILLS: '/skills',
    TRAININGS: '/trainings',
    ASSIGN_COMPANY: '/assign/company',
    ASSIGN_OFFICE: '/assign/office',
    ASSIGN_GROUP: '/assign/group',
    REMOVE_GROUP: '/remove/group',
    ASSETS_ASSIGN: '/assets/assign',
    ASSETS_REMOVE: '/assets/remove',
    ASSIGN_DEVICE: '/assign/device',
    REMOVE_DEVICE: '/remove/device',
    ASSIGN_SHIFT: '/shifts/assign',
    DELETE_SHIFT: '/shifts/delete',
    ASSIGN_SALARY_BASIC: '/salaries/assign/basic',
    ASSIGN_SALARY_TAX: '/salaries/assign/tax',
    TRANSFER: '/transfers',
    PROMOTION: '/promotions',
    DISCIPLINARY: '/disciplinaries',
    // GET STATIC VALUE
    OFFENSES: 'EMPLOYEE_/disciplinaries/offenses',
    LANGUAGES_PROFICIENCY: 'EMPLOYEE_/languages/proficiencies',
    SEPARATION_TYPES: 'EMPLOYEE_/separation/types',
    SEPARATION_STATUSES: 'EMPLOYEE_/separation/statuses',
    SEPARATION_MODES: 'EMPLOYEE_/separation/modes',
    GENDERS: 'EMPLOYEE_/general/genders',
    EFFICIENCIES: 'EMPLOYEE_/skills/efficiencies',
    RELIGIONS: 'EMPLOYEE_/general/religions',
    EDUCATION_TYPES: 'EMPLOYEE_/educations/types',
    EDUCATION_DURATION_UNITS: 'EMPLOYEE_/educations/duration-units',
    BLOOD_GROUPS: 'EMPLOYEE_/blood-groups',
    MARITAL_STATUSES: 'EMPLOYEE_/family/marital-statuses',
  },
  remote_work: 'remote-work',
  remote_work_type: 'REMOTE-WORK_/types',
  leave: 'leave',
  LEAVE: {
    TYPES: 'LEAVE_/types',
    DURATIONS: 'LEAVE_/durations',
  },
  kpis: 'kpis',
  kpis_type: 'KPIS_/types',
  kpis_status: 'KPIS_/statuses',
  KPI: {
    YEARS: 'KPI_/years',
    objective_perspectives: 'KPI_/objectives/perspectives',
  },
  KPI_ACTIONS: {
    KPI_UPDATE_REQUEST: 'actions/update-requested',
  },
  eprs: 'eprs',
  eprs_type: 'EPRS_/types',
  eprs_status: 'EPRS_/statuses',
  EPRS: {
    DEVELOPMENT_AREA: 'EPRS_/development-areas',
  },
  serviceR: 'employee/service-requests',
  serviceRS: 'serviceR_/statuses',
  serviceRC: 'serviceR_/categories',
  serviceRNT: 'serviceR_/noc/types',
  serviceRSA: 'serviceR_/sign-authorities',
  signAuthService: 'employee/service-requests/sign-authorities',
  groups: 'groups',
  GROUPS: {
    TREES: '/trees',
  },
  exams: 'employees/educations/exams',
  subjects: '/subjects',
  offices: 'offices',
  shifts: 'shifts',
  holidays: 'holidays',
  companies: 'companies',
  report: 'report',
  REPORT: {
    ATTENDANCE_INDIVIDUAL: 'REPORT_/attendance/individual',
    ATTENDANCE_SUMMARY: 'REPORT_/attendance/summary',
  },
  attendance_manual: 'attendances/manual',
  activity: 'employee/activities',
  ACTIVITY: {
    CATEGORIES: 'ACTIVITY_/categories',
    GROUPS: 'ACTIVITY_/groups',
  },
  devices: 'offices/devices',
  DEVICES: {
    DEVICES_BRANDS: 'DEVICES_/brands',
  },
  notices: 'notices',
};
